export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'tuntun',
        email: 'tuntun@shipclues.in',
        authority: ['admin', 'user'],
        password: 'testing@123',
        accountUserName: 'tuntun',
    },
]
